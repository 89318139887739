<template>
  <Page>
    <div class="home-page">
      <zui-header/>
      <div class="content-box">
        <div class="banner-box">
          <img class="welcome" src="https://www.achencybe.com/img/pc/order/1.png"/>
          <img :src="bannerImg" style="width: 1300px; margin-top: 40px"/>
        </div>
        <!--        v-if="type != 'carlistTHE5'"-->
        <template>
          <div class="shop-box">
            <div class="tips-text">
              {{ $t('order.main.title_1') }}
              <el-link
                  @click="
                $router.push({
                  name: 'FooterDetail',
                  query: { type: 'taobao' },
                })
              "
              >{{ $t('order.main.title_2') }}
              </el-link
              >
              {{ $t('order.main.title_3') }}
              <!--              ToDo 隐藏价格体系-->
              <div class="type-box" :style="{width: (materialList.length * 200) + 'px'}"
                   v-show="type === 'carlistBMW3'">
                <div v-for="(item,idx) in materialList" :key="idx" :class="{ active: materialType === idx }"
                     @click="materialType = idx">{{ item }}
                </div>
              </div>
            </div>
            <div class="shop-list">
              <div v-for="item in shopList" :key="item.id">
                <div v-if="!item.hidden">
                  <div class="line"></div>
                  <div class="shop-item">
                    <img :src="item.img"/>
                    <div class="shop-info">
                      <h2>{{ $t(item.title) }}</h2>
                      <span>{{ $t(item.info) }}</span>
                      <ul>
                        <li v-for="li in item.list" :key="li">{{ $t(li) }}</li>
                      </ul>
                      <div class="attr-box" v-if="item.attrList && item.attrList.length <= 2">
                        <div v-for="(attr, idx) in item.attrList" :key="idx">
                          <h2 style=" white-space:nowrap">{{ $t(attr.type) }}</h2>
                          <div v-if="attr.subType" style="margin: 10px 0">
                            {{ $t(attr.subType) }}
                          </div>
                          <div v-if="attr.x3 && attr.title" style="margin: 10px 0">
                            {{ $t(attr.title) }}
                          </div>
                          <button
                              :class="{ active:  attr.active }"
                              :style="{ paddingLeft: isPresell == '1' ? '40px' : '' }"
                              @click="hanldercheck(item, attr, idx)"
                              style="
                          font-family: 'ELEMENT-ICONS';
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                          >
                          <span v-if="isPresell === '1'" style="font-size: 22px; width: auto; font-weight: 500">
                            {{ $t('order.main.deposit') }}
                          </span>
                            <span v-else style="font-size: 22px; width: auto; font-weight: 500">

                          </span>
                            <span style="font-size: 22px; width: auto; font-weight: 500"
                                  v-if="language=== 'zh'">{{ formatPrice(attr.price) }}</span>
                            <span style="font-size: 22px; width: auto; font-weight: 500"
                                  v-else>{{ formatPrice(attr.price_dollar) }}</span>

                          </button>
                        </div>
                      </div>
                      <div v-if="item.attrList && item.attrList.length > 2">
                        <div :class="attr.subType === '' ? 'attr-box': 'attr-box-margin-min'"
                             v-for="(attr, idx) in item.attrList" :key="idx">
                          <div v-for="(attrInner, idcx) in attr.innerList" :key="idcx">
                            <h2 v-if="attrInner.type !== ''" style=" white-space:nowrap">{{ $t(attrInner.type) }}</h2>
                            <div v-if="attrInner.subType" style="margin: 10px 0">
                              {{ $t(attrInner.subType) }}
                            </div>
                            <button
                                :class="{ active:  attrInner.active }"
                                :style="{ paddingLeft: isPresell == '1' ? '40px' : '' }"
                                @click="handlerCheckInner(item, attr, attrInner)"
                                style="
                          font-family: 'ELEMENT-ICONS';
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                            >
                          <span v-if="isPresell === '1'" style="font-size: 22px; width: auto; font-weight: 500">
                            {{ $t('order.main.deposit') }}
                          </span>
                              <span v-else style="font-size: 22px; width: auto; font-weight: 500">

                          </span>

                              <span style="font-size: 22px; width: auto; font-weight: 500"
                                    v-if="language=== 'zh'">{{ formatPrice(attrInner.price) }}</span>
                              <span style="font-size: 22px; width: auto; font-weight: 500"
                                    v-else>{{ formatPrice(attrInner.price_dollar) }}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <span style="color: #a9a9a9" v-if="language === 'zh'">
                    {{ $t('order.main.send.send_title') }} {{ clientAdders }}<br/>
                    {{ $t('order.main.send.send_estimation') }}{{ completionTime }} ({{ week }})
                    {{ $t('order.main.send.send_finish') }}
                  </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="buy-box">
            <div>{{ $t('order.main.footer') }}</div>
            <button @click="toGo">{{ $t('order.main.footer_button') }}</button>
          </div>
          <div style="display: none;" v-if="language = this.$i18n.locale === 'zh' ? 'zh': 'en' "></div>
        </template>
        <!--        <template v-else>-->
        <!--          <div class="empty-box">-->
        <!--            <div>We will be back soon.</div>-->
        <!--            <div>产品正在准备中，马上回来。</div>-->
        <!--          </div>-->
        <!--        </template>-->

      </div>
      <zui-footer/>
    </div>
  </Page>
</template>
<script>
import "../../less/home-page.less";
import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiFooter from "../../../components/Footer";

const cname = returnCitySN.cname;
export default {
  components: {Page, ZuiHeader, ZuiFooter},
  computed: {
    type() {
      return this.$route.query.cartype
          ? this.$route.query.cartype
          : "";
    },
    materialList() {
      if (this.type === "carlistM3") {
        return this.$i18n.locale === 'zh' ? ["碳纤维"] : ["CARBON"];
      } else if (this.type === "carlistBMW3") {
        return this.$i18n.locale === 'zh' ? ["复合碳纤维", "碳纤维"] : ["COMPOSITE CARBON", "CARBON"];
      } else {
        return [];
      }
    },
    bannerImg() {
      if (this.type === "carlistTHE5") {
        return "https://www.achencybe.com/img/pc/order/the5/2-0-1.png";
      } else if (this.type === "carlistM3") {
        return "https://www.achencybe.com/img/pc/order/M4/2-0-1.png";
      } else if (this.type === "carlistTHE4") {
        return "https://www.achencybe.com/img/pc/the4/4-9-1.png";
      } else if (this.type === "carlistX3") {
        return "https://www.achencybe.com/img/pc/x3/X3-9-1.png";
      } else if (this.type === "carlistBMW3") {
        return "https://www.achencybe.com/img/pc/order/M3/1-0-1.png";
      } else if (this.type === "carlistchallenger") {
        return "https://www.achencybe.com/img/pc/order/challenger/3-0-1.png";
      } else if (this.type === "rim") {
        return "https://www.achencybe.com/img/pc/order/rim/2-0-1.png";
      } else {
        return "https://www.achencybe.com/img/pc/order/M4/2-0-1.png";
      }
    },
  },
  watch: {
    type: {
      language(val) {
        this.language = val
      },
      handler(val) {
        let valTypeOption = {
          carlistTHE5: "THE 5",
          carlistTHE4: "THE 4",
          carlistM3: "M3/M4",
          carlistBMW3: "The 3",
          carlistX3: "THE X3",
          carlistchallenger: "Challgner SRT",
          rim: "锻造轮辋"
        }
        let data = {carModelName: valTypeOption[val]};
        if (val === "carlistM3") {
          this.materialType = 0;
        }
        this.getList(data);
      },
      immediate: true
    },
    materialType() {
      let data = {
        carModelName:
            this.type === "carlistM3"
                ? "M3/M4"
                : this.type === "carlistBMW3"
                    ? "The 3"
                    : this.type === "carlistX3"
                        ? "THE X3"
                        : this.type === "rim"
                            ? "rim"
                            : "Challgner SRT"
      };
      if (this.type === "rim") {
        this.isRim = true
      }
      this.getList(data);
    },
  },
  data() {
    return {
      language: this.$i18n.locale,
      shopList: [] ,
      hasInnerList: false,
      completionTime: "",
      week: "",
      isRim: false,
      clientAdders: cname,
      isPresell: null,
      materialType: 1,
    };
  },
  mounted() {
    if (window.sessionStorage.getItem("isCompletion")) {
      window.sessionStorage.removeItem("isCompletion");
      this.shopList = JSON.parse(window.localStorage.getItem("activeShopList"));
    }
    window.localStorage.removeItem("activeShopList");
    window.localStorage.removeItem("shopCar");
  },
  methods: {
    formatPrice(val) {
      if (val == null || val === "null" || val == '') {
        val = "0"
      }
      let unit = this.language === 'zh' ? '￥ ' : '$ '
      return val.toString().replace(/\d+/, function (n) {
        return unit + n.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      });
    },
    toGo() {
      let hasShop = false;
      this.shopList.forEach((item) => {
        if (!this.hasInnerList) {
          item.attrList.forEach((item) => {
            if (item.active) {
              hasShop = true;
            }
          });
        } else {
          item.attrList.forEach((item) => {
            if (item.innerList) {
              item.innerList.forEach((item) => {
                if (item.active) {
                  hasShop = true;
                }
              });
            } else {
              if (item.active) {
                hasShop = true;
              }
            }
          });
        }
      });
      if (!hasShop) {
        let warn = this.$t('order.main.warning');
        this.$message.warning(warn);
        return;
      }
      window.localStorage.setItem(
          "activeShopList",
          JSON.stringify(this.shopList)
      );
      if (!localStorage.getItem("ticket")) {
        window.sessionStorage.setItem("isCompletion", JSON.stringify(this.$route.query));
        this.$router.push({name: "LoginPage"});
      }
          // else if (
          //     !sessionStorage.getItem("userInfo") ||
          //     (this.$store.state.userInfo && !this.$store.state.userInfo.mobile)
          // ) {
          //   window.sessionStorage.setItem("isCompletion", JSON.stringify(this.$route.query));
          //   this.$message({
          //     type: "warning",
          //     message: "请先补全手机号"
          //   });
          //   let timer = setTimeout(() => {
          //     this.$router.push({name: "VerifyPhonePage"});
          //     clearTimeout(timer);
          //   }, 800);
      // }
      else {
        this.$router.push({
          name: "SettlementPage",
          query: {cartype: this.type, completionTime: this.completionTime}
        });
      }
    },
    hanldercheck(item, attr, idx) {
      attr.active = !attr.active;
      attr.num = attr.active ? 1 : 0;
      if (item.attrList.length > 1) {
        item.attrList.forEach((item, index) => {
          if (index !== idx) {
            item.active = false;
            item.num = 0;
          }
        });
      }
    },
    handlerCheckInner(item, attr, attrInner) {
      attrInner.active = !attrInner.active;
      attrInner.num = attrInner.active ? 1 : 0;
      if (item.attrList.length > 1) {
        item.attrList.forEach((item, index) => {
          item.innerList.forEach((innerItem, indexInner) => {
            if (attrInner.index !== innerItem.index) {
              innerItem.active = false;
              innerItem.num = 0;
            }
          })
        });
      }
    },
    getList(data) {
      this.$api
          .getCarDetail(data)
          .then((res) => {
            if (res.code === 0) {
              this.isPresell = res.data.isPresell;
              res.data.listAccessoriesList.sort((a, b) => {
                return a.partsIndex - b.partsIndex;
              });
              this.shopList = []
              if (this.type === "carlistchallenger") {
                //挑战者
                this.shopList = [
                  {
                    id: 1,
                    img: "https://www.achencybe.com/img/pc/order/challenger/3-0-2-1.png",
                    title: "order.car_list.DODGE.challenger.items.item_1.title",
                    info: "order.car_list.DODGE.challenger.items.item_1.info",
                    list: ["order.car_list.DODGE.challenger.items.item_1.list.list_1", "order.car_list.DODGE.challenger.items.item_1.list.list_2"],
                    attrList: [
                      {
                        id: res.data.listAccessoriesList[0].id,
                        type: "order.car_list.DODGE.challenger.items.item_1.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[0].partsPrice
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[0].partsPriceDollar
                                : res.data.advanceDeposit,
                        title: "order.car_list.DODGE.challenger.items.item_1.attr_list.item_1.title",
                        partsPrice: res.data.listAccessoriesList[0].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && this.shopList[0].attrList[0].active ? 1 : 0,
                        active: this.shopList.length ? this.shopList[0].attrList[0].active : false
                      }
                    ]
                  },
                  {
                    id: 2,
                    img: "https://www.achencybe.com/img/pc/order/challenger/3-0-2-2.png",
                    title: "order.car_list.DODGE.challenger.items.item_2.title",
                    info: "order.car_list.DODGE.challenger.items.item_2.info",
                    list: ["order.car_list.DODGE.challenger.items.item_2.list.list_1", "order.car_list.DODGE.challenger.items.item_2.list.list_2"],
                    attrList: [
                      {
                        id: res.data.listAccessoriesList[1].id,
                        type: "order.car_list.DODGE.challenger.items.item_2.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[1].partsPrice
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[1].partsPriceDollar
                                : res.data.advanceDeposit,
                        title: "order.car_list.DODGE.challenger.items.item_2.attr_list.item_1.title",
                        partsPrice: res.data.listAccessoriesList[1].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && this.shopList[1].attrList[0].active ? 1 : 0,
                        active: this.shopList.length ? this.shopList[1].attrList[0].active : false
                      }
                    ]
                  },
                  {
                    id: 3,
                    img: "https://www.achencybe.com/img/pc/order/challenger/3-0-2-3.png",
                    title: "order.car_list.DODGE.challenger.items.item_3.title",
                    info: "order.car_list.DODGE.challenger.items.item_3.info",
                    list: ["order.car_list.DODGE.challenger.items.item_3.list.list_1", "order.car_list.DODGE.challenger.items.item_3.list.list_2"],
                    attrList: [
                      {
                        id: res.data.listAccessoriesList[2].id,
                        type: "order.car_list.DODGE.challenger.items.item_3.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[2].partsPrice
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[2].partsPriceDollar
                                : res.data.advanceDeposit,
                        title: "order.car_list.DODGE.challenger.items.item_3.attr_list.item_1.title",
                        partsPrice: res.data.listAccessoriesList[2].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && this.shopList[2].attrList[0].active ? 1 : 0,
                        active: this.shopList.length ? this.shopList[2].attrList[0].active : false
                      }
                    ]
                  },
                  {
                    id: 4,
                    img: "https://www.achencybe.com/img/pc/order/challenger/3-0-2-4.png",
                    title: "order.car_list.DODGE.challenger.items.item_4.title",
                    info: "order.car_list.DODGE.challenger.items.item_4.info",
                    list: ["order.car_list.DODGE.challenger.items.item_4.list.list_1", "order.car_list.DODGE.challenger.items.item_4.list.list_2"],
                    attrList: [
                      {
                        id: res.data.listAccessoriesList[3].id,
                        type: "order.car_list.DODGE.challenger.items.item_4.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[3].partsPrice
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[3].partsPriceDollar
                                : res.data.advanceDeposit,
                        title: "order.car_list.DODGE.challenger.items.item_4.attr_list.item_1.title",
                        partsPrice: res.data.listAccessoriesList[3].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && this.shopList[3].attrList[0].active ? 1 : 0,
                        active: this.shopList.length ? this.shopList[3].attrList[0].active : false
                      }
                    ]
                  }
                ];
              } else if (this.type === "carlistBMW3") {
                //THE3
                this.shopList = [
                  {
                    id: 5,
                    img: "https://www.achencybe.com/img/pc/order/M3/1-0-2-5.png",
                    title: "order.car_list.BMW.the3.items.item_5.title",
                    info: "order.car_list.BMW.the3.items.item_5.info",
                    list: ["order.car_list.BMW.the3.items.item_5.list.list_1", "order.car_list.BMW.the3.items.item_5.list.list_2"],
                    hidden: this.materialType !== 1,
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[5].id : res.data.listAccessoriesList[14].id,
                        type: "order.car_list.BMW.the3.items.item_5.attr_list.item_1.type",
                        subType: "order.car_list.BMW.the3.items.item_5.attr_list.item_1.sub_title",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[5].partsPrice : res.data.listAccessoriesList[14].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[5].partsPriceDollar : res.data.listAccessoriesList[14].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the3.items.item_5.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[5].partsPrice : res.data.listAccessoriesList[14].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && this.shopList[0].attrList[0].active ? 1 : 0,
                        active: this.shopList.length ? this.shopList[0].attrList[0].active : false
                      },
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[18].id : res.data.listAccessoriesList[19].id,
                        type: "order.car_list.BMW.the3.items.item_5.attr_list.item_2.type",
                        subType: "order.car_list.BMW.the3.items.item_5.attr_list.item_2.sub_title",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[18].partsPrice : res.data.listAccessoriesList[19].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[18].partsPriceDollar : res.data.listAccessoriesList[19].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the3.items.item_5.attr_list.item_2.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[18].partsPrice : res.data.listAccessoriesList[19].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && (this.shopList[0].attrList[1] ? this.shopList[0].attrList[1].active : false) ? 1 : 0,
                        active: this.shopList.length ? (this.shopList[0].attrList[1] ? this.shopList[0].attrList[1].active : false) : false
                      },
                    ]
                  },
                  {
                    id: 6,
                    img: "https://www.achencybe.com/img/pc/order/M3/1-0-2-6.png",
                    title: "order.car_list.BMW.the3.items.item_6.title",
                    info: "order.car_list.BMW.the3.items.item_6.info",
                    list: ["order.car_list.BMW.the3.items.item_6.list.list_1", "order.car_list.BMW.the3.items.item_6.list.list_2"],
                    hidden: this.materialType !== 1,
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[7].id : res.data.listAccessoriesList[16].id,
                        type: "order.car_list.BMW.the3.items.item_6.attr_list.item_1.type",
                        subType: "order.car_list.BMW.the3.items.item_6.attr_list.item_1.sub_title",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[7].partsPrice : res.data.listAccessoriesList[16].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[7].partsPriceDollar : res.data.listAccessoriesList[16].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the3.items.item_6.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[7].partsPrice : res.data.listAccessoriesList[16].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && this.shopList[1].attrList[0].active ? 1 : 0,
                        active: this.shopList.length ? this.shopList[1].attrList[0].active : false
                      },
                    ]
                  },
                  {
                    id: 2,
                    img: "https://www.achencybe.com/img/pc/order/M3/1-0-2-2.png",
                    title: "order.car_list.BMW.the3.items.item_2.title",
                    info: "order.car_list.BMW.the3.items.item_2.info",
                    list: ["order.car_list.BMW.the3.items.item_2.list.list_1", "order.car_list.BMW.the3.items.item_2.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[1].id : res.data.listAccessoriesList[10].id,
                        type: "order.car_list.BMW.the3.items.item_2.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[1].partsPrice : res.data.listAccessoriesList[10].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[1].partsPriceDollar : res.data.listAccessoriesList[10].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the3.items.item_2.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[1].partsPrice : res.data.listAccessoriesList[10].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && (this.shopList[2].attrList[0] ? this.shopList[2].attrList[0].active : false) ? 1 : 0,
                        active: this.shopList.length ? (this.shopList[2].attrList[0] ? this.shopList[2].attrList[0].active : false) : false
                      }
                    ]
                  },
                  {
                    id: 7,
                    img: "https://www.achencybe.com/img/pc/order/M3/1-0-2-7.png",
                    title: "order.car_list.BMW.the3.items.item_7.title",
                    info: "order.car_list.BMW.the3.items.item_7.info",
                    list: ["order.car_list.BMW.the3.items.item_7.list.list_1", "order.car_list.BMW.the3.items.item_7.list.list_2"],
                    hidden: this.materialType !== 1,
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[6].id : res.data.listAccessoriesList[15].id,
                        type: "order.car_list.BMW.the3.items.item_7.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[6].partsPrice : res.data.listAccessoriesList[15].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[6].partsPriceDollar : res.data.listAccessoriesList[15].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the3.items.item_7.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[6].partsPrice : res.data.listAccessoriesList[15].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && (this.shopList[3].attrList[0] ? this.shopList[3].attrList[0].active : false) ? 1 : 0,
                        active: this.shopList.length ? (this.shopList[3].attrList[0] ? this.shopList[3].attrList[0].active : false) : false
                      }
                    ]
                  },
                  {
                    id: 8,
                    img: "https://www.achencybe.com/img/pc/order/M3/1-0-2-8.png",
                    title: "order.car_list.BMW.the3.items.item_8.title",
                    info: "order.car_list.BMW.the3.items.item_8.info",
                    list: ["order.car_list.BMW.the3.items.item_8.list.list_1", "order.car_list.BMW.the3.items.item_8.list.list_2"],
                    hidden: this.materialType !== 1,
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[8].id : res.data.listAccessoriesList[17].id,
                        type: "order.car_list.BMW.the3.items.item_8.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[8].partsPrice : res.data.listAccessoriesList[17].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[8].partsPriceDollar : res.data.listAccessoriesList[17].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the3.items.item_8.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[8].partsPrice : res.data.listAccessoriesList[17].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && (this.shopList[4].attrList[0] ? this.shopList[4].attrList[0].active : false) ? 1 : 0,
                        active: this.shopList.length ? (this.shopList[4].attrList[0] ? this.shopList[4].attrList[0].active : false) : false
                      }
                    ]
                  },
                  {
                    id: 1,
                    img: "https://www.achencybe.com/img/pc/order/M3/1-0-2-1.png",
                    title: "order.car_list.BMW.the3.items.item_1.title",
                    info: "order.car_list.BMW.the3.items.item_1.info",
                    list: ["order.car_list.BMW.the3.items.item_1.list.list_1", "order.car_list.BMW.the3.items.item_1.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[0].id : res.data.listAccessoriesList[9].id,
                        type: "order.car_list.BMW.the3.items.item_1.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[0].partsPrice : res.data.listAccessoriesList[9].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[0].partsPriceDollar : res.data.listAccessoriesList[9].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the3.items.item_1.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[0].partsPrice : res.data.listAccessoriesList[9].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && this.shopList[5].attrList[0].active ? 1 : 0,
                        active: this.shopList.length ? this.shopList[5].attrList[0].active : false
                      }
                    ]
                  },


                  {
                    id: 3,
                    img: "https://www.achencybe.com/img/pc/order/M3/1-0-2-3.png",
                    title: "order.car_list.BMW.the3.items.item_3.title",
                    info: "order.car_list.BMW.the3.items.item_3.info",
                    list: ["order.car_list.BMW.the3.items.item_3.list.list_1", "order.car_list.BMW.the3.items.item_3.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[2].id : res.data.listAccessoriesList[11].id,
                        type: "order.car_list.BMW.the3.items.item_3.attr_list.item_1.type",
                        subType: "order.car_list.BMW.the3.items.item_3.attr_list.item_1.sub_title",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[2].partsPrice : res.data.listAccessoriesList[11].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[2].partsPriceDollar : res.data.listAccessoriesList[11].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the3.items.item_3.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[2].partsPrice : res.data.listAccessoriesList[11].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && this.shopList[6].attrList[0].active ? 1 : 0,
                        active: this.shopList.length ? this.shopList[6].attrList[0].active : false
                      },
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[3].id : res.data.listAccessoriesList[12].id,
                        type: "order.car_list.BMW.the3.items.item_3.attr_list.item_2.type",
                        subType: "order.car_list.BMW.the3.items.item_3.attr_list.item_2.sub_title",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[3].partsPrice : res.data.listAccessoriesList[12].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[3].partsPriceDollar : res.data.listAccessoriesList[12].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the3.items.item_3.attr_list.item_2.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[3].partsPrice : res.data.listAccessoriesList[12].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && this.shopList[6].attrList[1] && this.shopList[6].attrList[1].active ? 1 : 0,
                        active: this.shopList.length ? (this.shopList[6].attrList[1] ? this.shopList[6].attrList[1].active : false) : false
                      }
                    ]
                  },
                  {
                    id: 4,
                    img: "https://www.achencybe.com/img/pc/order/M3/1-0-2-4.png",
                    title: "order.car_list.BMW.the3.items.item_4.title",
                    info: "order.car_list.BMW.the3.items.item_4.info",
                    list: ["order.car_list.BMW.the3.items.item_4.list.list_1", "order.car_list.BMW.the3.items.item_4.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[4].id : res.data.listAccessoriesList[13].id,
                        type: "order.car_list.BMW.the3.items.item_4.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[4].partsPrice : res.data.listAccessoriesList[13].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[4].partsPriceDollar : res.data.listAccessoriesList[13].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the3.items.item_4.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[4].partsPrice : res.data.listAccessoriesList[13].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && this.shopList[7].attrList[0].active ? 1 : 0,
                        active: this.shopList.length ? this.shopList[7].attrList[0].active : false
                      }
                    ]
                  }
                ];
              } else if (this.type === "carlistTHE5") {
                this.shopList = [
                  {
                    id: 1,
                    img: "https://www.achencybe.com/img/pc/order/the5/1-0-2-1.png",
                    title: "order.car_list.BMW.the5.items.item_1.title",
                    info: "order.car_list.BMW.the5.items.item_1.info",
                    list: ["order.car_list.BMW.the5.items.item_1.list.list_1", "order.car_list.BMW.the5.items.item_1.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[0].id : res.data.listAccessoriesList[5].id,
                        type: "order.car_list.BMW.the5.items.item_1.attr_list.item_1.type",
                        subType: "order.car_list.BMW.the5.items.item_1.attr_list.item_1.sub_title",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[0].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[0].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the5.items.item_1.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[0].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[0].attrList[0].active : false,
                      },
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[1].id : res.data.listAccessoriesList[5].id,
                        type: "order.car_list.BMW.the5.items.item_1.attr_list.item_2.type",
                        subType: "order.car_list.BMW.the5.items.item_1.attr_list.item_2.sub_title",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[1].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[1].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the5.items.item_1.attr_list.item_2.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[1].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[0].attrList[1].active : false,
                      },
                    ],
                  },
                  {
                    id: 6,
                    img: "https://www.achencybe.com/img/pc/order/the5/1-0-2-6.png",
                    title: "order.car_list.BMW.the5.items.item_6.title",
                    info: "order.car_list.BMW.the5.items.item_6.info",
                    list: ["order.car_list.BMW.the5.items.item_6.list.list_1", "order.car_list.BMW.the5.items.item_6.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[6].id : res.data.listAccessoriesList[6].id,
                        type: "order.car_list.BMW.the5.items.item_6.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[6].partsPrice : res.data.listAccessoriesList[6].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[6].partsPriceDollar : res.data.listAccessoriesList[6].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the5.items.item_6.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[6].partsPrice : res.data.listAccessoriesList[6].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[6].attrList[0].active : false
                      },
                    ],
                  },
                  {
                    id: 2,
                    img: "https://www.achencybe.com/img/pc/order/the5/1-0-2-2.png",
                    title: "order.car_list.BMW.the5.items.item_2.title",
                    info: "order.car_list.BMW.the5.items.item_2.info",
                    list: ["order.car_list.BMW.the5.items.item_2.list.list_1", "order.car_list.BMW.the5.items.item_2.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[2].id : res.data.listAccessoriesList[6].id,
                        type: "order.car_list.BMW.the5.items.item_2.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[2].partsPrice : res.data.listAccessoriesList[6].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[2].partsPriceDollar : res.data.listAccessoriesList[6].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the5.items.item_2.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[2].partsPrice : res.data.listAccessoriesList[6].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[2].attrList[0].active : false
                      }
                    ],
                  },
                  {
                    id: 3,
                    img: "https://www.achencybe.com/img/pc/order/the5/1-0-2-3.png",
                    title: "order.car_list.BMW.the5.items.item_3.title",
                    info: "order.car_list.BMW.the5.items.item_3.info",
                    list: ["order.car_list.BMW.the5.items.item_3.list.list_1", "order.car_list.BMW.the5.items.item_3.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[3].id : res.data.listAccessoriesList[6].id,
                        type: "order.car_list.BMW.the5.items.item_3.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[3].partsPrice : res.data.listAccessoriesList[6].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[3].partsPriceDollar : res.data.listAccessoriesList[6].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the5.items.item_3.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[3].partsPrice : res.data.listAccessoriesList[6].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[3].attrList[0].active : false
                      },

                    ],
                  },
                  {
                    id: 4,
                    img: "https://www.achencybe.com/img/pc/order/the5/1-0-2-4.png",
                    title: "order.car_list.BMW.the5.items.item_4.title",
                    info: "order.car_list.BMW.the5.items.item_4.info",
                    list: ["order.car_list.BMW.the5.items.item_4.list.list_1", "order.car_list.BMW.the5.items.item_4.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[4].id : res.data.listAccessoriesList[6].id,
                        type: "order.car_list.BMW.the5.items.item_4.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[4].partsPrice : res.data.listAccessoriesList[6].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[4].partsPriceDollar : res.data.listAccessoriesList[6].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the5.items.item_4.attr_list.item_1.type",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[4].partsPrice : res.data.listAccessoriesList[6].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[4].attrList[0].active : false
                      }
                    ],
                  },
                  {
                    id: 5,
                    img: "https://www.achencybe.com/img/pc/order/the5/1-0-2-5.png",
                    title: "order.car_list.BMW.the5.items.item_5.title",
                    info: "order.car_list.BMW.the5.items.item_5.info",
                    list: ["order.car_list.BMW.the5.items.item_5.list.list_1", "order.car_list.BMW.the5.items.item_5.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[5].id : res.data.listAccessoriesList[6].id,
                        type: "order.car_list.BMW.the5.items.item_5.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[5].partsPrice : res.data.listAccessoriesList[6].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[5].partsPriceDollar : res.data.listAccessoriesList[6].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the5.items.item_5.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[5].partsPrice : res.data.listAccessoriesList[6].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[5].attrList[0].active : false
                      }
                    ],
                  },

                ];
              } else if (this.type === "carlistTHE4") {
                this.shopList = [
                  {
                    id: 1,
                    img: "https://www.achencybe.com/img/pc/the4/4-10-1.png",
                    title: "order.car_list.BMW.the4.items.item_1.title",
                    info: "order.car_list.BMW.the4.items.item_1.info",
                    list: ["order.car_list.BMW.the4.items.item_1.list.list_1", "order.car_list.BMW.the4.items.item_1.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[0].id : res.data.listAccessoriesList[5].id,
                        type: "order.car_list.BMW.the4.items.item_1.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[0].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[0].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the4.items.item_1.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[0].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[0].attrList[0].active : false,
                      },
                    ],
                  },
                  {
                    id: 2,
                    img: "https://www.achencybe.com/img/pc/the4/4-10-2.png",
                    title: "order.car_list.BMW.the4.items.item_2.title",
                    info: "order.car_list.BMW.the4.items.item_2.info",
                    list: ["order.car_list.BMW.the4.items.item_2.list.list_1", "order.car_list.BMW.the4.items.item_2.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[1].id : res.data.listAccessoriesList[5].id,
                        type: "order.car_list.BMW.the4.items.item_2.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[1].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[1].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the4.items.item_2.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[1].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[1].attrList[0].active : false
                      }
                    ],
                  },
                  {
                    id: 3,
                    img: "https://www.achencybe.com/img/pc/the4/4-10-3.png",
                    title: "order.car_list.BMW.the4.items.item_3.title",
                    info: "order.car_list.BMW.the4.items.item_3.info",
                    list: ["order.car_list.BMW.the4.items.item_3.list.list_1", "order.car_list.BMW.the4.items.item_3.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[2].id : res.data.listAccessoriesList[5].id,
                        type: "order.car_list.BMW.the4.items.item_3.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[2].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[2].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the4.items.item_3.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[2].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[2].attrList[0].active : false
                      },

                    ],
                  },
                  {
                    id: 4,
                    img: "https://www.achencybe.com/img/pc/the4/4-10-4.png",
                    title: "order.car_list.BMW.the4.items.item_4.title",
                    info: "order.car_list.BMW.the4.items.item_4.info",
                    list: ["order.car_list.BMW.the4.items.item_4.list.list_1", "order.car_list.BMW.the4.items.item_4.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[3].id : res.data.listAccessoriesList[5].id,
                        type: "order.car_list.BMW.the4.items.item_4.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[3].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[3].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the4.items.item_4.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[3].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[3].attrList[0].active : false
                      },
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[4].id : res.data.listAccessoriesList[4].id,
                        type: "order.car_list.BMW.the4.items.item_4.attr_list.item_2.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[4].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[4].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the4.items.item_4.attr_list.item_2.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[4].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[4].attrList[0].active : false
                      }
                    ],
                  },
                  {
                    id: 5,
                    img: "https://www.achencybe.com/img/pc/the4/4-10-5.png",
                    title: "order.car_list.BMW.the4.items.item_5.title",
                    info: "order.car_list.BMW.the4.items.item_5.info",
                    list: ["order.car_list.BMW.the4.items.item_5.list.list_1", "order.car_list.BMW.the4.items.item_5.list.list_2"],
                    attrList: [
                      {
                        id: res.data.listAccessoriesList[5].id,
                        type: "order.car_list.BMW.the4.items.item_5.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[5].partsPrice
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[5].partsPriceDollar
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.the4.items.item_5.attr_list.item_1.title",
                        partsPrice: res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[5].attrList[0].active : false
                      }
                    ],
                  },
                ];
              } else if (this.type === "carlistX3") {
                this.shopList = [
                  {
                    id: 1,
                    img: "https://www.achencybe.com/img/pc/x3/X3-10-1.png",
                    title: "order.car_list.BMW.x3.items.item_1.title",
                    info: "order.car_list.BMW.x3.items.item_1.info",
                    list: ["order.car_list.BMW.x3.items.item_1.list.list_1", "order.car_list.BMW.x3.items.item_1.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[0].id : res.data.listAccessoriesList[5].id,
                        type: "order.car_list.BMW.x3.items.item_1.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[0].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[0].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.x3.items.item_1.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[0].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[0].attrList[0].active : false,
                        x3: true,
                      },
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[1].id : res.data.listAccessoriesList[4].id,
                        type: "order.car_list.BMW.x3.items.item_1.attr_list.item_2.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[1].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[1].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.x3.items.item_1.attr_list.item_2.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[1].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[0].attrList[0].active : false,
                        x3: true,
                      }
                    ],
                  },
                  {
                    id: 2,
                    img: "https://www.achencybe.com/img/pc/x3/X3-10-2.png",
                    title: "order.car_list.BMW.x3.items.item_2.title",
                    info: "order.car_list.BMW.x3.items.item_2.info",
                    list: ["order.car_list.BMW.x3.items.item_2.list.list_1", "order.car_list.BMW.x3.items.item_2.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[2].id : res.data.listAccessoriesList[5].id,
                        type: "order.car_list.BMW.x3.items.item_2.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[2].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[2].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.x3.items.item_2.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[2].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[1].attrList[0].active : false,
                        x3: true,
                      },
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[3].id : res.data.listAccessoriesList[4].id,
                        type: "",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[3].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[3].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.x3.items.item_2.attr_list.item_2.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[3].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[2].attrList[0].active : false,
                        x3: true,
                      }
                    ],
                  },
                  {
                    id: 3,
                    img: "https://www.achencybe.com/img/pc/x3/X3-10-3.png",
                    title: "order.car_list.BMW.x3.items.item_3.title",
                    info: "order.car_list.BMW.x3.items.item_3.info",
                    list: ["order.car_list.BMW.x3.items.item_3.list.list_1", "order.car_list.BMW.x3.items.item_3.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[4].id : res.data.listAccessoriesList[5].id,
                        type: "order.car_list.BMW.x3.items.item_3.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[4].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[4].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.x3.items.item_3.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[4].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[3].attrList[0].active : false,
                        x3: true,
                      },

                    ],
                  },
                  {
                    id: 4,
                    img: "https://www.achencybe.com/img/pc/x3/X3-10-4.png",
                    title: "order.car_list.BMW.x3.items.item_4.title",
                    info: "order.car_list.BMW.x3.items.item_4.info",
                    list: ["order.car_list.BMW.x3.items.item_4.list.list_1", "order.car_list.BMW.x3.items.item_4.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[5].id : res.data.listAccessoriesList[5].id,
                        type: "order.car_list.BMW.x3.items.item_4.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[5].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[5].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.x3.items.item_4.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[5].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[4].attrList[0].active : false,
                        x3: true,
                      },
                    ],
                  },
                ];
              } else if (this.type === "rim") {
                console.log(res.data.listAccessoriesList)
                this.hasInnerList = true;
                this.shopList = [
                  {
                    id: 1,
                    img: "https://www.achencybe.com/img/pc/order/rim/1-0-2-1.png",
                    title: "order.car_list.rim.items.item_1.title",
                    info: "order.car_list.rim.items.item_1.info",
                    list: ["order.car_list.rim.items.item_1.list.list_1", "order.car_list.rim.items.item_1.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[0].id : res.data.listAccessoriesList[5].id,
                        type: "order.car_list.rim.items.item_1.attr_list.item_1.type",
                        subType: "order.car_list.rim.items.item_1.attr_list.item_1.sub_title",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[0].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[0].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.rim.items.item_1.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[0].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[0].attrList[0].active : false,
                      },
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[1].id : res.data.listAccessoriesList[5].id,
                        type: "order.car_list.rim.items.item_1.attr_list.item_2.type",
                        subType: "order.car_list.rim.items.item_1.attr_list.item_2.sub_title",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[1].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[1].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.rim.items.item_1.attr_list.item_2.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[1].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[0].attrList[1].active : false,
                      },
                    ],
                  },
                  {
                    id: 2,
                    img: "https://www.achencybe.com/img/pc/order/rim/1-0-2-2.png",
                    title: "order.car_list.rim.items.item_2.title",
                    info: "order.car_list.rim.items.item_2.info",
                    list: ["order.car_list.rim.items.item_2.list.list_1", "order.car_list.rim.items.item_2.list.list_2"],
                    attrList: [
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[0].id : res.data.listAccessoriesList[5].id,
                        type: "order.car_list.rim.items.item_2.attr_list.item_1.type",
                        subType: "order.car_list.rim.items.item_2.attr_list.item_1.sub_title",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[0].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[0].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.rim.items.item_2.attr_list.item_1.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[0].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[1].attrList[0].active : false,
                        innerList: [
                          {
                            id: this.materialType === 1 ? res.data.listAccessoriesList[2].id : res.data.listAccessoriesList[5].id,
                            type: "order.car_list.rim.items.item_2.attr_list.item_1.inner_list.item_1.type",
                            subType: "order.car_list.rim.items.item_2.attr_list.item_1.inner_list.item_1.sub_title",
                            index: res.data.listAccessoriesList[2].partsIndex,
                            price:
                                res.data.isPresell == "0"
                                    ? (this.materialType === 1 ? res.data.listAccessoriesList[2].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                    : res.data.advanceDeposit,
                            price_dollar:
                                res.data.isPresell == "0"
                                    ? (this.materialType === 1 ? res.data.listAccessoriesList[2].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                    : res.data.advanceDeposit,
                            title: "order.car_list.rim.items.item_2.attr_list.item_1.inner_list.item_1.title",
                            partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[2].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                            advanceDeposit: res.data.advanceDeposit,
                            num: 0,
                            active: this.shopList.length ? this.shopList[1].attrList[1].active : false,
                          },
                          {
                            id: this.materialType === 1 ? res.data.listAccessoriesList[3].id : res.data.listAccessoriesList[5].id,
                            type: "order.car_list.rim.items.item_2.attr_list.item_1.inner_list.item_2.type",
                            subType: "order.car_list.rim.items.item_2.attr_list.item_1.inner_list.item_2.sub_title",
                            index: res.data.listAccessoriesList[3].partsIndex,
                            price:
                                res.data.isPresell == "0"
                                    ? (this.materialType === 1 ? res.data.listAccessoriesList[3].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                    : res.data.advanceDeposit,
                            price_dollar:
                                res.data.isPresell == "0"
                                    ? (this.materialType === 1 ? res.data.listAccessoriesList[3].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                    : res.data.advanceDeposit,
                            title: "order.car_list.rim.items.item_2.attr_list.item_1.inner_list.item_2.title",
                            partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[3].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                            advanceDeposit: res.data.advanceDeposit,
                            num: 0,
                            active: this.shopList.length ? this.shopList[1].attrList[1].active : false,
                          },
                        ]
                      },
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[1].id : res.data.listAccessoriesList[5].id,
                        type: "order.car_list.rim.items.item_2.attr_list.item_2.type",
                        subType: "order.car_list.rim.items.item_2.attr_list.item_2.sub_title",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[1].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[1].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.rim.items.item_2.attr_list.item_2.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[1].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[0].attrList[1].active : false,
                        innerList: [
                          {
                            id: this.materialType === 1 ? res.data.listAccessoriesList[4].id : res.data.listAccessoriesList[5].id,
                            type: "order.car_list.rim.items.item_2.attr_list.item_2.inner_list.item_1.type",
                            subType: "order.car_list.rim.items.item_2.attr_list.item_2.inner_list.item_1.sub_title",
                            index: res.data.listAccessoriesList[4].partsIndex,
                            price:
                                res.data.isPresell == "0"
                                    ? (this.materialType === 1 ? res.data.listAccessoriesList[4].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                    : res.data.advanceDeposit,
                            price_dollar:
                                res.data.isPresell == "0"
                                    ? (this.materialType === 1 ? res.data.listAccessoriesList[4].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                    : res.data.advanceDeposit,
                            title: "order.car_list.rim.items.item_2.attr_list.item_2.inner_list.item_1.title",
                            partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[4].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                            advanceDeposit: res.data.advanceDeposit,
                            num: 0,
                            active: this.shopList.length ? this.shopList[1].attrList[1].active : false,
                          }
                        ]
                      },
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[1].id : res.data.listAccessoriesList[5].id,
                        type: "order.car_list.rim.items.item_2.attr_list.item_3.type",
                        subType: "order.car_list.rim.items.item_2.attr_list.item_3.sub_title",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[1].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[1].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.rim.items.item_2.attr_list.item_3.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[1].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[0].attrList[1].active : false,
                        innerList: [
                          {
                            id: this.materialType === 1 ? res.data.listAccessoriesList[5].id : res.data.listAccessoriesList[5].id,
                            type: "order.car_list.rim.items.item_2.attr_list.item_3.inner_list.item_1.type",
                            subType: "order.car_list.rim.items.item_2.attr_list.item_3.inner_list.item_1.sub_title",
                            index: res.data.listAccessoriesList[5].partsIndex,
                            price:
                                res.data.isPresell == "0"
                                    ? (this.materialType === 1 ? res.data.listAccessoriesList[5].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                    : res.data.advanceDeposit,
                            price_dollar:
                                res.data.isPresell == "0"
                                    ? (this.materialType === 1 ? res.data.listAccessoriesList[5].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                    : res.data.advanceDeposit,
                            title: "order.car_list.rim.items.item_2.attr_list.item_3.inner_list.item_1.title",
                            partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[5].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                            advanceDeposit: res.data.advanceDeposit,
                            num: 0,
                            active: this.shopList.length ? this.shopList[1].attrList[1].active : false,
                          },
                          {
                            id: this.materialType === 1 ? res.data.listAccessoriesList[6].id : res.data.listAccessoriesList[5].id,
                            type: "order.car_list.rim.items.item_2.attr_list.item_3.inner_list.item_2.type",
                            subType: "order.car_list.rim.items.item_2.attr_list.item_3.inner_list.item_2.sub_title",
                            index: res.data.listAccessoriesList[6].partsIndex,
                            price:
                                res.data.isPresell == "0"
                                    ? (this.materialType === 1 ? res.data.listAccessoriesList[6].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                    : res.data.advanceDeposit,
                            price_dollar:
                                res.data.isPresell == "0"
                                    ? (this.materialType === 1 ? res.data.listAccessoriesList[6].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                    : res.data.advanceDeposit,
                            title: "order.car_list.rim.items.item_2.attr_list.item_3.inner_list.item_2.title",
                            partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[6].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                            advanceDeposit: res.data.advanceDeposit,
                            num: 0,
                            active: this.shopList.length ? this.shopList[1].attrList[1].active : false,
                          },
                        ]
                      },
                      {
                        id: this.materialType === 1 ? res.data.listAccessoriesList[1].id : res.data.listAccessoriesList[5].id,
                        type: "order.car_list.rim.items.item_2.attr_list.item_4.type",
                        subType: "order.car_list.rim.items.item_2.attr_list.item_4.sub_title",
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[1].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? res.data.listAccessoriesList[1].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: "order.car_list.rim.items.item_2.attr_list.item_4.title",
                        partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[1].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[0].attrList[1].active : false,
                        innerList: [
                          {
                            id: this.materialType === 1 ? res.data.listAccessoriesList[7].id : res.data.listAccessoriesList[5].id,
                            type: "order.car_list.rim.items.item_2.attr_list.item_4.inner_list.item_1.type",
                            subType: "order.car_list.rim.items.item_2.attr_list.item_4.inner_list.item_1.sub_title",
                            index: res.data.listAccessoriesList[7].partsIndex,
                            price:
                                res.data.isPresell == "0"
                                    ? (this.materialType === 1 ? res.data.listAccessoriesList[7].partsPrice : res.data.listAccessoriesList[5].partsPrice)
                                    : res.data.advanceDeposit,
                            price_dollar:
                                res.data.isPresell == "0"
                                    ? (this.materialType === 1 ? res.data.listAccessoriesList[7].partsPriceDollar : res.data.listAccessoriesList[5].partsPriceDollar)
                                    : res.data.advanceDeposit,
                            title: "order.car_list.rim.items.item_2.attr_list.item_4.inner_list.item_1.title",
                            partsPrice: this.materialType === 1 ? res.data.listAccessoriesList[7].partsPrice : res.data.listAccessoriesList[5].partsPrice,
                            advanceDeposit: res.data.advanceDeposit,
                            num: 0,
                            active: this.shopList.length ? this.shopList[1].attrList[1].active : false,
                          },
                        ]
                      },
                    ],
                  }
                ];
              } else {
                this.shopList = [
                  {
                    id: 1,
                    img: "https://www.achencybe.com/img/pc/order/M4/2-0-2-1.png",
                    title: "order.car_list.BMW.M3.items.item_1.title",
                    info: "order.car_list.BMW.M3.items.item_1.info",
                    list: ["order.car_list.BMW.M3.items.item_1.list.list_1", "order.car_list.BMW.M3.items.item_1.list.list_2"],
                    attrList: [
                      {
                        id: res.data.listAccessoriesList[0].id,
                        type: "order.car_list.BMW.M3.items.item_1.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[0].partsPrice
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[0].partsPriceDollar
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.M3.items.item_1.attr_list.item_1.title",
                        partsPrice: res.data.listAccessoriesList[0].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && this.shopList[0].attrList[0].active ? 1 : 0,
                        active: this.shopList.length ? this.shopList[0].attrList[0].active : false
                      }
                    ]
                  },
                  {
                    id: 2,
                    img: "https://www.achencybe.com/img/pc/order/M4/2-0-2-2.png",
                    title: "order.car_list.BMW.M3.items.item_2.title",
                    info: "order.car_list.BMW.M3.items.item_2.info",
                    list: ["order.car_list.BMW.M3.items.item_2.list.list_1", "order.car_list.BMW.M3.items.item_2.list.list_2"],
                    attrList: [
                      {
                        id: res.data.listAccessoriesList[1].id,
                        type: "order.car_list.BMW.M3.items.item_2.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[1].partsPrice
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[1].partsPriceDollar
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.M3.items.item_2.attr_list.item_1.title",
                        partsPrice: res.data.listAccessoriesList[1].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && this.shopList[1].attrList[0].active ? 1 : 0,
                        active: this.shopList.length ? this.shopList[1].attrList[0].active : false
                      },
                      {
                        id: res.data.listAccessoriesList[2].id,
                        type: "order.car_list.BMW.M3.items.item_2.attr_list.item_2.type",
                        price:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[2].partsPrice
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[2].partsPriceDollar
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.M3.items.item_2.attr_list.item_2.title",
                        partsPrice: res.data.listAccessoriesList[2].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && (this.shopList[1].attrList[1] ? this.shopList[1].attrList[1].active : false) ? 1 : 0,
                        active: this.shopList.length ? (this.shopList[1].attrList[1] ? this.shopList[1].attrList[1].active : false) : false
                      }
                    ]
                  },
                  {
                    id: 3,
                    img: "https://www.achencybe.com/img/pc/order/M4/2-0-2-3.png",
                    title: "order.car_list.BMW.M3.items.item_3.title",
                    info: "order.car_list.BMW.M3.items.item_3.info",
                    list: ["order.car_list.BMW.M3.items.item_3.list.list_1", "order.car_list.BMW.M3.items.item_3.list.list_2"],
                    attrList: [
                      {
                        id: res.data.listAccessoriesList[3].id,
                        type: "order.car_list.BMW.M3.items.item_3.attr_list.item_1.type",
                        // subType: "配备标准Spoiler Guys组件",
                        price:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[3].partsPrice
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[3].partsPriceDollar
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.M3.items.item_3.attr_list.item_1.title",
                        partsPrice: res.data.listAccessoriesList[3].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && this.shopList[2].attrList[0].active ? 1 : 0,
                        active: this.shopList.length ? this.shopList[2].attrList[0].active : false
                      },
                      {
                        id: res.data.listAccessoriesList[4].id,
                        type: "order.car_list.BMW.M3.items.item_3.attr_list.item_2.type",
                        // subType: "配备碳纤维拓展Spoiler Guys组件",
                        price:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[4].partsPrice
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[4].partsPriceDollar
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.M3.items.item_3.attr_list.item_2.type",
                        partsPrice: res.data.listAccessoriesList[4].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && this.shopList[2].attrList[1] && this.shopList[2].attrList[1].active ? 1 : 0,
                        active: this.shopList.length ? (this.shopList[2].attrList[1] ? this.shopList[2].attrList[1].active : false) : false
                      }
                    ]
                  },
                  {
                    id: 4,
                    img: "https://www.achencybe.com/img/pc/order/M4/2-0-2-4.png",
                    title: "order.car_list.BMW.M3.items.item_4.title",
                    info: "order.car_list.BMW.M3.items.item_4.info",
                    list: ["order.car_list.BMW.M3.items.item_4.list.list_1", "order.car_list.BMW.M3.items.item_4.list.list_2"],
                    attrList: [
                      {
                        id: res.data.listAccessoriesList[5].id,
                        type: "order.car_list.BMW.M3.items.item_4.attr_list.item_1.type",
                        price:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[5].partsPrice
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? res.data.listAccessoriesList[5].partsPriceDollar
                                : res.data.advanceDeposit,
                        title: "order.car_list.BMW.M3.items.item_4.attr_list.item_1.title",
                        partsPrice: res.data.listAccessoriesList[5].partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: this.shopList.length && this.shopList[3].attrList[0].active ? 1 : 0,
                        active: this.shopList.length ? this.shopList[3].attrList[0].active : false
                      }
                    ]
                  }
                ];
              }
              // if (res.data.isPresell == "0") {
              //   this.completionTime = res.data.completionTime
              //     ? this.$timeFormat(res.data.completionTime, "yyyy年mm月dd日")
              //     : "";
              //   this.week = res.data.completionTime
              //     ? this.$weekFormat(res.data.completionTime)
              //     : "";
              // } else if (res.data.isPresell == "1") {
              //   this.completionTime = res.data.releaseTime
              //     ? this.$timeFormat(res.data.releaseTime, "yyyy年mm月dd日")
              //     : "";
              //   this.week = res.data.releaseTime
              //     ? this.$weekFormat(res.data.releaseTime)
              //     : "";
              // }
              if (res.data.isPresell == "0") {
                this.completionTime = res.data.completionTime
                    ? res.data.completionTime.split(" ")[0]
                    : "";
                this.week = res.data.completionTime
                    ? this.$weekFormat(res.data.completionTime.split(" ")[0].replace(/-/g, "/"))
                    : "";
              } else if (res.data.isPresell == "1") {
                this.completionTime = res.data.releaseTime
                    ? res.data.releaseTime.split(" ")[0]
                    : "";
                this.week = res.data.releaseTime
                    ? this.$weekFormat(res.data.releaseTime.split(" ")[0].replace(/-/g, "/"))
                    : "";
              }
            }
          })
          .catch((err) => {
            console.error("getCarDetail", err);
          });
    }
  }
};
</script>


<style lang="scss" scoped>
@import "../../scss/OrderPage.scss";

.empty-box {
  margin: 160px 0;
  display: flex;
  flex-direction: column;

  div:first-child {
    font-size: 100px;
    line-height: 1;
  }

  div:last-child {
    font-size: 48px;
    line-height: 1;
    margin-top: 20px;
  }
}
</style>
